import tokens from './tokens'
import { SerializedFarmConfig } from './types'

const priceHelperLps: SerializedFarmConfig[] = [
  /**
   * These LPs are just used to help with price calculation for MasterChef LPs (farms.ts).
   * This list is added to the MasterChefLps and passed to fetchFarm. The calls to get contract information about the token/quoteToken in the LP are still made.
   * The absence of a PID means the masterchef contract calls are skipped for this farm.
   * Prices are then fetched for all farms (masterchef + priceHelperLps).
   * Before storing to redux, farms without a PID are filtered out.
   */
  {
    pid: null,
    lpSymbol: 'SWPR-USDT LP',
    lpAddresses: {
      97: '0x93E71e4232185F8c0A02f4F2138E82Dfd8F54a20',
      56: '0x8AB407Cb5Af93566E21a2604377F9f0C9A5182aC',
    },
    token: tokens.swpr,
    quoteToken: tokens.usdt,
  },
]

export default priceHelperLps
