import { Address } from '../types'

export enum SwapperyCollectionKey {
  PANSWPR = 'swappery',
  SQUAD = 'swapperySquad',
}

export type SwapperyCollection = {
  name: string
  description?: string
  slug: string
  address: Address
}

export type SwapperyCollections = {
  [key in SwapperyCollectionKey]: SwapperyCollection
}
