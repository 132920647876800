import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
import { CHAIN_ID } from './networks'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 3, 11) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'SWPR',
    lpAddresses: {
      97: '0x1081ab2e3bdA6b41E1fb555a0ecf8d5487d5D23E',
      56: '0x6FA23529476a1337EB5da8238b778e7122d79666',
    },
    token: serializedTokens.syrup,
    quoteToken: serializedTokens.wbnb,
  },

  {
    pid: 3,
    lpSymbol: 'WCSPR-SWPR LP',
    lpAddresses: {
      97: '0xDD2e120Af98EC5580d6969dE63a04573B2FBd87D',
      56: '0x77d70Fde6841bcebe2F2F5e8f8A93469EB0C2376',
    },
    token: serializedTokens.wcspr,
    quoteToken: serializedTokens.swpr,
  },
  {
    pid: 4,
    lpSymbol: 'WCSPR-CSPD LP',
    lpAddresses: {
      97: '0xCB6E82085FFAc3F5f9a6C89833EE565603C0C67b',
      56: '0xfac9BEDac82AB509deC7AFc2dB0539Afb7F0d704',
    },
    token: serializedTokens.wcspr,
    quoteToken: serializedTokens.cspd,
  },
  {
    pid: 1,
    lpSymbol: 'SWPR-USDT LP',
    lpAddresses: {
      97: '0x9fC87cBE2fF4971913e77fE46962feEbB2362777',
      56: '0x8AB407Cb5Af93566E21a2604377F9f0C9A5182aC',
    },
    token: serializedTokens.swpr,
    quoteToken: serializedTokens.usdt,
  },
  {
    pid: 2,
    lpSymbol: 'CSPD-SWPR LP',
    lpAddresses: {
      97: '0x34fA2e9FC75b16941Db38d97dB1f36a764b2D11A',
      56: '0x255ADA43Cc767aD614097322F31936b37f6101be',
    },
    token: serializedTokens.cspd,
    quoteToken: serializedTokens.swpr,
  },
].filter((f) => !!f.lpAddresses[CHAIN_ID])

export default farms
