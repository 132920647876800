import React from 'react'
import { Button, Text, useWalletModal } from '@swappery/uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'

const ConnectWalletButton = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, t)

  return (
    <Button onClick={onPresentConnectModal} {...props}>
      <Text color="black" fontFamily="Orator-Std" fontWeight="800" fontSize="12px">
        CONNECT WALLET
      </Text>
    </Button>
  )
}

export default ConnectWalletButton
